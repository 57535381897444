import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Get in touch</h2>
        <p>I am nice</p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <section>
            <h4>Contact me</h4>
            <form
              method="post"
              action="https://formspree.io/contact@celinechole.com"
            >
              <div className="row gtr-uniform">
                <div className="col-6 col-12-xsmall">
                  <input
                    type="text"
                    name="name"
                    id="demo-name"
                    defaultValue=""
                    placeholder="Name"
                  />
                </div>
                <div className="col-6 col-12-xsmall">
                  <input
                    type="email"
                    name="demo-email"
                    id="demo-email"
                    defaultValue=""
                    placeholder="Email"
                  />
                </div>
                <div className="col-12">
                  <textarea
                    name="demo-message"
                    id="demo-message"
                    placeholder="Enter your message"
                    rows="6"
                  ></textarea>
                </div>
                <div className="col-12">
                  <ul className="actions">
                    <li>
                      <input
                        type="submit"
                        value="Send Message"
                        className="primary"
                      />
                    </li>
                    <li>
                      <input type="reset" value="Reset" />
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </article>
  </Layout>
);

export default IndexPage;
